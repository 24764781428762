import React, { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const FAQSection = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const questions = [
    {
      question: "What services does Sparkstart Solutions offer?",
      answer:
        "We offer a wide range of services, including: Web and mobile app development , Social media management, Graphic design and branding, IoT development and Legal, financial, and team management solutions.",
    },
    {
      question: "Why should we choose Sparkstart Solutions?",
      answer:
         "Affordable and transparent pricing End-to-end business solutions under one roof, Timely delivery with 24/7 support , Experienced team of developers, designers, and strategists.",
    },
    {
      question: "How do I get started with Sparkstart Solutions?",
      answer:
        "Simply reach out to us via:📧 Email: support@sparkstartsolutions.tech🌐 Website: sparkstartsolutions.tech Our team will guide you through the process.",
    },
    {
      question: "Do you work with international clients?",
      answer:
       "Yes, we work with clients globally. Our team is experienced in handling international projects, ensuring seamless collaboration across time zones.",
    },
  ];

  return (
    <section id="faq" className="bg-gradient-to-b from-[#F9F7F6] to-[#EAE8E1] h-screen  rounded-3xl ">
      <motion.div 
      variants={fadeIn("up", 0.2)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.6 }}
      className="container max-w-4xl px-6 py-10 mx-auto">
        <h1 className="text-4xl font-ClearfaceRegular  text-center text-gray-800 lg:text-3xl ">
          Common Queries Answered
        </h1>

        <div className="mt-12 space-y-8">
          {questions.map((item, index) => (
            <div
              key={index}
              className="border-2 border-gray-100 rounded-lg "
            >
              <button
                className="flex items-center justify-between w-full p-8"
                onClick={() => toggleQuestion(index)}
              >
                <h1 className="font-semibold text-gray-700 md:text-xl ">
                  {item.question}
                </h1>
                <span
                  className={`rounded-full ${
                    openQuestion === index
                      ? "text-pink-500"
                      : "text-pink-500 "
                  }`}
                >
                  {openQuestion === index ? (
                    // <svg
                    //   xmlns="http://www.w3.org/2000/svg"
                    //   className="w-6 h-6"
                    //   fill="gray-500"
                    //   viewBox="0 0 24 24"
                    //   stroke="currentColor"
                    // >
                    //   <path
                    //     strokeLinecap="round"
                    //     strokeLinejoin="round"
                    //     strokeWidth="2"
                    //     d="M18 12H6"
                    //   />
                    // </svg>
                    <IoIosArrowUp className=" w-4 h-4"/>
                  ) : (
                    // <svg
                    //   xmlns="http://www.w3.org/2000/svg"
                    //   className="w-6 h-6"
                    //   fill="gray-500"
                    //   viewBox="0 0 24 24"
                    //   stroke="currentColor"
                    // >
                    //   <path
                    //     strokeLinecap="round"
                    //     strokeLinejoin="round"
                    //     strokeWidth="2"
                    //     d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    //   />
                    // </svg>
                    <IoIosArrowDown className=" w-5 h-5"/>
                  )}
                </span>
              </button>

              {openQuestion === index && item.answer && (
                <>
                  <hr className="border-gray-200 " />
                  <p className="p-8 text-lg text-gray-500 ">
                    {item.answer}
                  </p>
                </>
              )}
            </div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default FAQSection;
