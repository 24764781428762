// import React from 'react'

// const tools = [
//   { id: 1, img: "./assets/tech/Aws--Streamline-Svg-Logos.svg" },
//   { id: 2, img: "./assets/tech/Figma--Streamline-Svg-Logos.svg"  },
//   { id: 3, img: "./assets/tech/Adobe-Xd--Streamline-Svg-Logos.svg"  },
//   { id: 4, img: "./assets/tech/Github-Icon--Streamline-Svg-Logos.svg"  },
//   { id: 5, img: "./assets/tech/Adobe-Photoshop--Streamline-Svg-Logos.svg"  },
//   { id: 6, img: "./assets/tech/Adobe-Illustrator--Streamline-Svg-Logos.svg" },
// ];

// const Technologies = () => {
//   return (
//     <div className="w-full flex overflow-x-auto bg-[#eae8e1] space-x-4 py-6 scrollbar-hide">
//       {tools.map((tool) => (
//         <div
//           key={tool.id}
//           className="flex-shrink-0 w-20 h-20 bg-[#F9F7F6] rounded-3xl shadow-lg flex items-center justify-center"
//         >
//           <img
//             src={tool.img}
//             alt={tool.name}
//             className="w-10 h-10 object-contain"
//           />
//         </div>
//       ))}
//     </div>
//   )
// }

// export default Technologies
// import React from "react";

// const Slider = () => {
//   const icons = [
//     "./assets/tech/Aws--Streamline-Svg-Logos.svg", // Replace with your icon URLs
//     "./assets/tech/Adobe-Xd--Streamline-Svg-Logos.svg",
//     "./assets/tech/Figma--Streamline-Svg-Logos.svg",
//     "./assets/tech/Adobe-Illustrator--Streamline-Svg-Logos.svg",
//     "./assets/tech/Adobe-Photoshop--Streamline-Svg-Logos.svg",
//     "./assets/tech/Github-Icon--Streamline-Svg-Logos.svg",
//   ];

//   return (
//     <div className="relative bg-gray-100 py-10">
//       <h2 className="text-center text-2xl font-semibold mb-6">Icon Slider</h2>

//       {/* Slider Container */}
//       <div className="overflow-hidden">
//         <div
//           className="flex gap-6 px-6 animate-scroll whitespace-nowrap"
//           style={{
//             willChange: "transform",
//             animation: "scroll 20s linear infinite",
//           }}
//         >
//           {/* Icons */}
//           {icons.map((icon, index) => (
//             <div
//               key={index}
//               className="flex-shrink-0 w-24 h-24 bg-white rounded-full shadow-lg flex items-center justify-center"
//             >
//               <img src={icon} alt={`Icon ${index + 1}`} className="w-12 h-12" />
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Tailwind custom animation */}
//       <style>{`
//         @keyframes scroll {
//           0% {
//             transform: translateX(0);
//           }
//           100% {
//             transform: translateX(-100%);
//           }
//         }
//           .animate-scroll {
//           animation: scroll 15s linear infinite;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default Slider;
// import React, { useEffect, useRef, useState } from "react";

// const ContinuousSlider = () => {
//   const icons = [
//     "./assets/tech/Aws--Streamline-Svg-Logos.svg", // Replace with your icon URLs
//      "./assets/tech/Adobe-Xd--Streamline-Svg-Logos.svg",
//    "./assets/tech/Figma--Streamline-Svg-Logos.svg",
//    "./assets/tech/Adobe-Illustrator--Streamline-Svg-Logos.svg",
//    "./assets/tech/Adobe-Photoshop--Streamline-Svg-Logos.svg",
//    "./assets/tech/Github-Icon--Streamline-Svg-Logos.svg",
//   ];

//   const [currentIcons, setCurrentIcons] = useState([...icons]); // State to manage visible icons
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const container = containerRef.current;
//     let interval;

//     if (container) {
//       interval = setInterval(() => {
//         // Move the first icon to the end
//         setCurrentIcons((prevIcons) => {
//           const [first, ...rest] = prevIcons;
//           return [...rest, first];
//         });
//         // Reset scroll position for smooth transition
//         container.scrollLeft = 0;
//       }, 2000); // Adjust the speed here (2 seconds per scroll)
//     }

//     return () => {
//       clearInterval(interval);
//     };
//   }, []);

//   return (
//     <div className="relative bg-gray-100 py-10 overflow-hidden">
//       <h2 className="text-center text-2xl font-semibold mb-6">Continuous Loop Slider</h2>

//       {/* Slider Container */}
//       <div
//         ref={containerRef}
//         className="flex gap-6 px-6 overflow-hidden"
//         style={{ width: "100%", scrollBehavior: "smooth" }}
//       >
//         {currentIcons.map((icon, index) => (
//           <div
//             key={index}
//             className="flex-shrink-0 w-24 h-24 bg-white rounded-full shadow-lg flex items-center justify-center"
//           >
//             <img src={icon} alt={`Icon ${index + 1}`} className="w-12 h-12" />
//           </div>
//         ))}
//       </div>
//       <style>{`
//       @keyframes scroll {
//   0% {
//     transform: translateX(0%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// }

// .animate-scroll {
//   display: flex;
//   gap: 1.5rem; /* Adjust gap between icons */
//   animation: scroll 10s linear infinite;
// }

//       `}

//       </style>
//     </div>
//   );
// };

// export default ContinuousSlider;
// import React from "react";

// const TechStackSlider = () => {
//   const techStack = [
//     { name: "React", icon: "🔷" },
//     { name: "Tailwind CSS", icon: "🎨" },
//     { name: "Node.js", icon: "🌳" },
//     { name: "MongoDB", icon: "🍃" },
//     { name: "AWS", icon: "☁️" },
//     { name: "Docker", icon: "🐳" },
//   ];

//   return (
//     <div className="relative overflow-hidden">
//       {/* Fading effect */}
//       <div className="absolute inset-0 z-10 pointer-events-none">
//         <div className="absolute top-0 left-0 w-16 h-full bg-gradient-to-r from-white"></div>
//         <div className="absolute top-0 right-0 w-16 h-full bg-gradient-to-l from-white"></div>
//       </div>

//       {/* Slider Container */}
//       <div className="flex items-center gap-8 py-4 px-6 animate-scroll">
//         {[...techStack, ...techStack].map((tech, index) => (
//           <div
//             key={index}
//             className="flex-shrink-0 text-center opacity-80 hover:opacity-100 transition-opacity duration-300"
//           >
//             <div className="text-4xl">{tech.icon}</div>
//             <p className="mt-2 text-sm">{tech.name}</p>
//           </div>
//         ))}
//       </div>

//       {/* Tailwind Scroll Animation */}
//       <style>{`
//         @keyframes scroll {
//           0% { transform: translateX(0); }
//           100% { transform: translateX(-50%); }
//         }
//         .animate-scroll {
//           display: flex;
//           animation: scroll 15s linear infinite;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default TechStackSlider;
import React from "react";

const TechStackSlider = () => {
  const techStack = [
    { id: 1, img: "./assets/tech/Aws--Streamline-Svg-Logos.svg" },
    { id: 2, img: "./assets/tech/Figma--Streamline-Svg-Logos.svg"  },
    { id: 3, img: "./assets/tech/Adobe-Xd--Streamline-Svg-Logos.svg"  },
    { id: 4, img: "./assets/tech/Github-Icon--Streamline-Svg-Logos.svg"  },
    { id: 5, img: "./assets/tech/Adobe-Photoshop--Streamline-Svg-Logos.svg"  },
    { id: 6, img: "./assets/tech/Adobe-Illustrator--Streamline-Svg-Logos.svg" },
    { id:7, img: "./assets/tech/chatgpticon.svg" },
    { id: 8, img: "./assets/tech/Aws--Streamline-Svg-Logos.svg" },
    { id: 9, img: "./assets/tech/Github-Icon--Streamline-Svg-Logos.svg"  },

    { id:10, img: "./assets/tech/jsicon.png" },
    { id:11, img: "./assets/tech/reacticon.png" },
    {  id:12,  img: "./assets/tech/Aws--Streamline-Svg-Logos.svg" }
    
  ];

  return (
    <div id="tech" className="relative overflow-hidden ">
      {/* Fading effect */}
      <h1 className=" font-ClearfaceRegular  md:mt-32  text-center text-gray-800 text-2xl md:text-4xl">Tech Stack we work with</h1>
      <div className="absolute inset-0 z-10 pointer-events-none">
        <div className="absolute top-0 left-0  w-5 h-full bg-gradient-to-r   "></div>
        <div className="absolute top-0 right-0 w-5 h-full bg-gradient-to-l "></div>
      </div>

      {/* Slider Wrapper */}
      <div className="flex gap-4 md:mt-20 mt-10  md:py-6 py-2  md:px-64 md:mb-32 items-center">
        {/* Sliding Content */}
        <div className="flex  gap-10 animate-infinite-scroll">
          {techStack.map((tech, index) => (
            <div
              key={tech.id}
              className="flex-shrink-0 text-center opacity-80 hover:opacity-100 transition-opacity duration-300"
            >
              <img src={tech.img} className=" md:h-20 md:w-auto"></img>
              
              {/* <p className="mt-2 text-sm">{tech.name}</p> */}
            </div>
          ))}
          {/* Duplicate content for seamless scrolling */}
          {/* {techStack.map((tech, index) => (
            <div
              key={`duplicate-${index}`}
              className="flex-shrink-0 text-center opacity-80 hover:opacity-100 transition-opacity duration-300"
            >
              <div className="text-4xl">{tech.icon}</div>
              <p className="mt-2 text-sm">{tech.name}</p>
            </div>
          ))} */}
        </div>
      </div>

     
    </div>
  );
};

export default TechStackSlider;





