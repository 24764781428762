import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Testimonials = () => {
  return (
    <section id='review' className="bg-[#EAE8E1]">
      <div
      variants={fadeIn("up", 0.2)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.6 }}
       className="container px-6 py-10 mx-auto">
        <div className="mt-6 md:flex md:items-center md:justify-between">
          <div>
            <h1 className="text-4xl text-gray-700 font-ClearfaceRegular lg:text-4xl">
              Trusted By Professionals
            </h1>
            {/* <p className=' text-base text-gray-500 font-ClearfaceRegular text-center'>While most of our client reviews are NDA-protected <br/> (because, you know, top-secret agency white label stuff), We managed to sneak in a few favorites <br/> from my previous partners.</p> */}

            <div className="flex mx-auto mt-6">
              <span className="inline-block w-40 h-1 bg-[#F4F2EF] rounded-full"></span>
              <span className="inline-block w-3 h-1 mx-1 bg-[#F4F2EF] rounded-full"></span>
              <span className="inline-block w-1 h-1 bg-[#F4F2EF] rounded-full"></span>
            </div>
          </div>

          {/* <div className="flex justify-between mt-8 md:mt-0">
            <button
              title="left arrow"
              className="p-2 mx-3 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:text-gray-200 dark:hover:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>

            <button
              title="right arrow"
              className="p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:text-gray-200 dark:hover:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div> */}
        </div>

        <section className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 lg:grid-cols-2 xl:grid-cols-3">
          <div className="p-8 bg-[#F4F2EF] border rounded-lg">
            <p className="leading-loose text-gray-900">
              “Working with SparkStart has been a game-changer for our app's user experience. Their innovative UI designs and seamless development have elevated our product to new heights. Kudos to Sparkstart for their expertise and dedication.”
            </p>

            <div className="bg-[#F4F2EF] flex items-center mt-8 -mx-2">
              <img
                className="object-cover mx-2 rounded-full w-14 shrink-0 h-14 ring-4 ring-gray-300"
                src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
                alt=""
              />

              {/* <div className="mx-2">
                <h1 className="font-semibold text-gray-800">Robert</h1>
                <span className="text-sm text-gray-500">CTO, Robert Consultancy</span>
              </div> */}
            </div>
          </div>

          <div className="p-8 bg-[#F4F2EF] border border-transparent rounded-lg">
            <p className="leading-loose text-black">
              “We collaborated with Sparkstart on a critical project and they exceeded our expectations. It's a pleasure to work with professionals who understand the art and science of user experience.”
            </p>

            <div className="bg-[#F4F2EF] flex items-center mt-8 -mx-2">
              <img
                className="object-cover mx-2 rounded-full w-14 shrink-0 h-14 ring-4 ring-blue-200"
                src="https://images.unsplash.com/photo-1531590878845-12627191e687?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80"
                alt=""
              />

              {/* <div className="mx-2">
                <h1 className="font-semibold text-white">Jeny Doe</h1>
                <span className="text-sm text-black">CEO, Jeny Consultancy</span>
              </div> */}
            </div>
          </div>

          <div className="p-8 bg-[#F4F2EF] border rounded-lg">
            <p className="leading-loose text-gray-900">
              “I recently had the pleasure of working with Sparkstart Solutions, and I must say, their expertise and dedication are truly impressive. The team demonstrated exceptional professionalism and a deep understanding of our business needs, delivering solutions that were both innovative and practical.”
            </p>

            <div className="flex bg-[#F4F2EF] items-center mt-8 -mx-2">
              <img
                className="object-cover mx-2 rounded-full w-14 shrink-0 h-14 ring-4 ring-gray-300"
                src="https://images.unsplash.com/photo-1488508872907-592763824245?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt=""
              />

              {/* <div className="mx-2">
                <h1 className="font-semibold text-gray-800">Ema Watson</h1>
                <span className="text-sm text-gray-500">Marketing Manager at Stech</span>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Testimonials;

