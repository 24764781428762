import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const HeroSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className=" h-screen w-full flex flex-col">
      {/* <nav className=" md:w-3/4 bg-white w-full text-lg shadow-lg font-ClearfaceRegular flex justify-between items-center md:h-16 h-10 mx-auto rounded-full">
        <img src="./assets/logo2.svg" className=" mx-7 md:h-16 md:w-16 h-10 w-10"></img>
        
        
        <div className=" flex gap-8">
          <a>Home</a>
          <a></a>
          <a>FAQs</a>
          <a>Reviews</a>
        
        </div>
        <button className=" mr-7">Get In Touch</button>
      </nav> */}
      
  

 
    <nav className="relative md:bg-white rounded-full md:w-3/4  md:mx-auto shadow-lg font-ClearfaceRegular">
      <div className="container w-full mx-auto flex justify-between items-center md:h-16 h-10 rounded-full">
        {/* Logo Section */}
        <a href="#" className="flex items-center">
          <img
            src="./assets/logo.svg"
            className="mx-7 md:h-32  md:mt-12 mt-6 md:w-32  h-16 w-16"
            alt="Logo"
          />
        </a>

        {/* Desktop Menu */}
        <div className="hidden md:flex gap-8">
          <a href="#process" className="hover:text-gray-600 text-xl">Process</a>
          <a href="#faq" className="hover:text-gray-600 text-xl">FAQs</a>
          <a href="#review" className="hover:text-gray-600 text-xl">Reviews</a>
        </div>
        <a href="#contact">
        <button className="hidden md:block mr-7 text-xl hover:text-gray-600">
          Get In Touch
        </button>
        </a>

        {/* Mobile Menu Button */}
        <div className="flex md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="text-gray-500 mr-5 hover:text-gray-600 focus:outline-none focus:text-gray-600"
            aria-label="toggle menu"
          >
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 8h16M4 16h16"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`md:hidden ${
          isOpen ? "block" : "hidden"
        } transition-all duration-300 bg-white shadow-lg`}
      >
        <div className="flex flex-col gap-4 px-6 py-4">
          <a href="#process" className=" text-center hover:text-gray-600">
            Process
          </a>
          <a href="#faq" className=" text-center hover:text-gray-600">
            FAQs
          </a>
          <a href="#review" className=" text-center hover:text-gray-600">
            Reviews
          </a>
          <a href="#contact">
           <button className="w-full px-4  text-center  text-black">
            Get In Touch
           </button>
          </a>
        </div>
      </div>
    </nav>


      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.6 }}
       className="flex md:flex-row   font-ClearfaceRegular md:justify-between md:items-center  flex-col">
        <div className="flex md:w-1/2   gap-14  flex-col">
          <div className=" flex flex-col gap-10 md:gap-4">
          <h1 className=" text-black  md:leading-normal text-3xl  mt-10 md:mt-0 md:mx-3 text-center md:text-start md:text-4xl">Transforming ideas into tailored business solutions</h1>
          <p className=" text-gray-700 mx-5 md:mx-3 text-center  md:text-start text-lg md:text-xl leading-normal">The ultimate multi-purpose creative and tech one stop solution</p>
          </div>
          <div>
            <a href="./assets/brochure.pdf"
             target="_blank"
             rel="noopener noreferrer"
            ><h2 className=" bg-gray-600 py-1 md:py-2 text-white md:w-1/4  w-1/2 rounded-xl md:rounded-full shadow-2xl  text-center md:mx-10   mx-auto   md:text-xl">Explore Services </h2></a>
          </div>
          <div>
            <img src="./assets/industrypartners.svg" className=" hidden md:block  w-3/4 mx-10 md:mx-3  md:w-1/2"></img>
          </div>
        </div>
        <div className=" -mt-10 md:mt-0  md:flex w-full md:w-1/2">
          {/* <img src="./assets/trial1.svg" className=" scale-150 relative "></img> */}
          <img src="./assets/herovideo.gif" className=" relative"></img>
          {/* <div className="">
            <video
             autoPlay
             loop
             muted
             src="./assets/collabifyvideo.mp4"
             className=" md:h-72 md:mt-48  md:w-52 md:-ml-80 "
            ></video>
          </div> */}
        </div>
      </motion.div>
    </div>
  
  );
};

export default HeroSection;
