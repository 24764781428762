import './App.css';
import HeroSection from './components/HeroSection';
import Services from './components/Services';
import Footer from './components/Footer';
import FAQs from './components/FAQs';
import ContactUs from './components/ContactUs';
import Testimonials from './components/Testimonials';
import TechStackSlider from './components/Technologies';
import Process from './components/Process';

function App() {
  return (
    <div className="  bg-[#EAE8E1] overflow-x-hidden h-screen w-full px-3 md:px-16 py-6 ">
      <HeroSection/>
      {/* <Services/> */}
      <TechStackSlider/>
      <Process/>
      <Testimonials/>
      <ContactUs/>
      <FAQs/>
      <Footer/>

   </div>
  );
}

export default App;
