import React from 'react';


const Footer = () => {
  return (
    <footer className=" mt-5   ">
      <div className="container p-6 mx-auto">
        <div className="lg:flex">
          <div className="w-full -mx-6 lg:w-2/5">
            <div className="px-6">
              <a href="#">
                <img className="w-auto h-24 scale-150 ml-5" src='./assets/logo.svg' alt="Logo" />
              </a>

              <p className="max-w-sm mt-2 text-gray-500 ">
              Helping Startups and Brands to craft expressive and engaging solutions for their needs.
              </p>

              <div className="flex mt-6 -mx-2">
                <a
                  href="#"
                  className="mx-2 text-gray-600 transition-colors duration-300  hover:text-blue-500 "
                  aria-label="Reddit"
                >
                  <svg
                    className="w-5 h-5 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* SVG Path for Reddit */}
                  </svg>
                </a>

                <a
                  href="#"
                  className="mx-2 text-gray-600 transition-colors duration-300  hover:text-blue-500 "
                  aria-label="Facebook"
                >
                  <svg
                    className="w-5 h-5 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* SVG Path for Facebook */}
                  </svg>
                </a>

                <a
                  href="#"
                  className="mx-2 text-gray-600 transition-colors duration-300  hover:text-blue-500 "
                  aria-label="Github"
                >
                  <svg
                    className="w-5 h-5 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* SVG Path for Github */}
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div className="mt-6 lg:mt-0 lg:flex-1">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              <div>
                
                <a
                  href="#"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  Home
                </a>
                <a
                  href="#"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  About
                </a>
                <a
                  href="#"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  Process
                </a>
              </div>

              <div>
                <h3 className="text-gray-700 uppercase ">Privacy</h3>
                <a
                  href="#"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  Tech
                </a>
                <a
                  href="#"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  Music
                </a>
                <a
                  href="#"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  Videos
                </a>
              </div>

              <div>
                <h3 className="text-gray-700 uppercase ">Products</h3>
                <a
                  href="#"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  Collabify
                </a>
                
              </div>

              <div>
                <h3 className="text-gray-700 uppercase ">Contact</h3>
                <span className="block mt-2 text-sm text-gray-600  hover:underline">
                  +1 526 654 8965
                </span>
                <span className="block mt-2 text-sm text-gray-600  hover:underline">
                  sparkstartsolutions@gmail.com
                </span>
              </div>
            </div>
          </div>
        </div>

        <hr className="h-px my-6 bg-gray-200 border-none " />

        <div>
          <p className="text-center text-gray-500 ">
            © SparkStart Solutions  - All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
