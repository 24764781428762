import React from 'react';
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";


const ContactSection = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");
    const formData = new FormData(event.target);

    formData.append("access_key", "47ebc805-423d-48f7-8a92-7340281afdfe");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.error("Error:", data);
      setResult(data.message || "Something went wrong. Please try again.");
    }
  };

  return (
    <section id='contact' className="min-h-screen rounded-xl mx-auto mb-6 bg-[#EAE8E1] lg:flex">
      <div className="flex flex-col rounded-xl justify-center w-full p-8 lg:bg-gray-100 lg:px-12 xl:px-32 lg:w-1/2">
        <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl">
          Hire Us
        </h1>
        <p className="mt-4 text-gray-500">
          Ask us anything; we would love to hear from you.
        </p>
        <div className=' mt-5 flex gap-5'>
          <a href='https://www.instagram.com/sparkstartsolutions?igsh=NTRvaGEzb2F5eDhp'
            target="_blank"
             rel="noopener noreferrer"
          ><FaInstagram className=' md:h-10 md:w-10'/></a>
          <a href='https://wa.me/+918379086707'
            target="_blank"
             rel="noopener noreferrer"
          ><FaWhatsapp className=' md:h-10 md:w-10'/></a>

        </div>
      </div>

      <div className="flex items-center justify-center w-full px-8 py-16 lg:w-1/2 lg:px-12 lg:py-24 xl:px-32">
        <div className="w-full max-w-xl mx-auto lg:mx-0">
          <h1 className="text-3xl font-ClearfaceRegular text-gray-800 capitalize lg:text-3xl">
            Let’s talk
          </h1>
          <p className="mt-4 text-gray-500">
            Share your details below, and we will get back to you as soon as possible.
          </p>

          <form onSubmit={onSubmit} className="mt-6 space-y-4">
            <div>
              <label className="block mb-2 text-sm text-gray-600">Your Name</label>
              <input
                type="text"
                name="name"
                placeholder="John Doe"
                className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
                required
              />
            </div>

            <div>
              <label className="block mb-2 text-sm text-gray-600">Email Address</label>
              <input
                type="email"
                name="email"
                placeholder="john.doe@example.com"
                className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
                required
              />
            </div>

            <div>
              <label className="block mb-2 text-sm text-gray-600">Message</label>
              <textarea
                name="message"
                placeholder="Your message here..."
                className="block w-full h-32 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full px-4 py-2 text-white bg-pink-500 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
            >
              Send Message
            </button>
          </form>

          <span className="block mt-4 text-gray-600">{result}</span>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;


// const ContactSection = () => {
//   return (
//     <section className="min-h-screen rounded-xl  mx-auto mb-6 bg-[#EAE8E1]  lg:flex">
//       <div className="flex flex-col rounded-xl justify-center w-full p-8 lg:bg-gray-100  lg:px-12 xl:px-32 lg:w-1/2">
//         <h1 className="text-2xl font-semibold text-gray-800 capitalize  lg:text-3xl">Hire Us</h1>
//         <p className="mt-4 text-gray-500 ">
//           Ask us anything; we would love to hear from you.
//         </p>
//       </div>

//       <div className="flex items-center justify-center w-full px-8 py-16 lg:w-1/2 lg:px-12 lg:py-24 xl:px-32">
//         <div className="w-full max-w-xl mx-auto lg:mx-0">
//           <h1 className="text-3xl font-ClearfaceRegular  text-gray-800 capitalize  lg:text-3xl">Let’s talk</h1>
//           <p className="mt-4 text-gray-500 ">
//             Share your details below, and we will get back to you as soon as possible.
//           </p>

//           <form className="mt-6 space-y-4">
//             <div>
//               <label className="block mb-2 text-sm text-gray-600 ">Your Name</label>
//               <input
//                 type="text"
//                 placeholder="John Doe"
//                 className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:ring focus:ring-blue-300 focus:outline-none"
//               />
//             </div>

//             <div>
//               <label className="block mb-2 text-sm text-gray-600 ">Email Address</label>
//               <input
//                 type="email"
//                 placeholder="john.doe@example.com"
//                 className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:text-gray-300  focus:ring focus:ring-blue-300 focus:outline-none"
//               />
//             </div>

//             <div>
//               <label className="block mb-2 text-sm text-gray-600 ">Message</label>
//               <textarea
//                 placeholder="Your message here..."
//                 className="block w-full h-32 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
//               ></textarea>
//             </div>

//             <button
//               type="submit"
//               className="w-full px-4 py-2 text-white bg-gray-400 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
//             >
//               Send Message
//             </button>
//           </form>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ContactSection;
