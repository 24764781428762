import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Process = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleDesc = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const points = [
    {
      question: "Understanding Your Needs and Strategic Planning",
      desc: "We start by listening to your goals, understanding your target audience, and discussing the specifics of your project. Our team develops a tailored strategy, outlining clear steps and deliverables.",
    },
    {
      question: "Design & Creation",
      desc: "We transform your ideas into impactful solutions through innovative design, seamless development, and creative storytelling.",
    },
    {
      question: "Collaboration is key!",
      desc: "We share progress with you at every stage, incorporating your feedback to refine and perfect the final output.",
    },
    {
      question: "Testing & Quality Assurance",
      desc: "Rigorous testing ensures that every deliverable meets our quality standards, whether it’s a bug-free app, a high-resolution video, or an error-free marketing campaign.",
    },
    {
      question: "Launch & Delivery",
      desc: "We deploy your solutions seamlessly, ensuring they’re optimized for real-world use. For marketing services, we launch campaigns strategically to maximize impact",
    }
  ];

  return (
    <section id='process' className="h-screen rounded-2xl bg-gradient-to-b from-[#F9F7F6] to-[#EAE8E1]">
      <div className="container px-6 md:py-12 mx-auto">
        <div 
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.6 }}
         className="mt-8 xl:mt-16 lg:flex lg:-mx-12">
          {/* Table of Content */}
          <div className='md:ml-20 md:mr-9'>
            <h1 className='font-ClearfaceRegular text-3xl text-gray-700'>Process</h1>
            <p className='md:mt-10 md:text-lg font-ClearfaceRegular'>
              Your compass to innovation and design excellence. <br />
              From exploration to execution, this dynamic framework <br />
              fuels creativity and precision, ensuring your product <br />
              journey aligns seamlessly with user desires.
            </p>
          </div>

          {/* FAQ Section */}
          <div className="flex-1 mt-8 lg:mx-12 lg:mt-0">
            {points.map((point, index) => (
              <div key={index} id={`point-${index}`}>
                <button
                  onClick={() => toggleDesc(index)}
                  className="flex items-center focus:outline-none w-full text-left"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`flex-shrink-0 w-6 h-6 ${openIndex === index ? "text-gray-500" : "text-gray-500"}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={`${openIndex === index ? "M20 12H4 " : "M12 4v16m8-8H4"}`}
                    />
                  </svg>

                  <h1 className="mx-4 text-xl font-ClearfaceRegular text-gray-700" onClick={() => toggleDesc(index)}>
                    {point.question}
                  </h1>
                </button>

                {openIndex === index && point.desc && (
                  <div className="flex mt-8 md:mx-10">
                    <span className="border border-gray-500"></span>
                    <p className="max-w-3xl px-4 font-ClearfaceRegular text-gray-500">
                      {point.desc}
                    </p>
                  </div>
                )}

                <hr className="my-8 border-gray-200" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;


